<qbit-dialog-header title="{{'CREATE_ACCOUNT'|translate}}" (click)="close()"></qbit-dialog-header>

<div class="bootstrap-wrapper">
    <form class="padd-20" [formGroup]="accountDetailsForm" (ngSubmit)="addAccount()">
        <div class="row">
            <div class="col-lg-12">
                <p class="text-small">
                    {{'CREATE_ACCOUNT_DESC' | translate}}
                </p>

                <sa-application-selector *ngIf="application | asyncp as app" userType="admin" [userType]="userRole"
                    (itemChange)="application = $event;" [disabled]="disableApplicationSelector">
                </sa-application-selector>

                <sa-application-selector *ngIf="!application" [userType]="userRole" [(item)]="application"
                    [disabled]="disableApplicationSelector">
                </sa-application-selector>

                <mat-form-field>
                    <mat-label>{{'NAME' | translate}}</mat-label>
                    <input id="name" matInput placeholder="Enter your name" formControlName="name">
                    <mat-error *ngIf="name.invalid && (name.dirty) && name.errors.required">
                        {{'NAME_REQUIRED' | translate}}
                    </mat-error>
                </mat-form-field>

                <div class="flex-row end">
                    <qbit-loadable-button [id]="'createAccount'"
                        [disabled]="accountDetailsForm.invalid || !application || isLoading" [loading]="isLoading">
                        {{(isLoading ? 'ADDING': 'ADD') |translate}}
                    </qbit-loadable-button>
                </div>
            </div>
        </div>
    </form>
</div>