<qbit-dialog-header title="{{'EDIT_USER'|translate}}" (click)="close()"></qbit-dialog-header>

<div class="bootstrap-wrapper">
    <form class="padd-20" [formGroup]="userForm" (ngSubmit)="add()">
        <div class="row">
            <div class="col-lg-12">
                <p class="text-small marg-bottom-20">
                    {{'EDIT_USER_DESC' | translate}}
                </p>

                <sa-application-selector *ngIf="application | asyncp as app" userType="admin" [item]="app"
                    (itemChange)="application = $event;"></sa-application-selector>
                    
                <sa-application-selector *ngIf="!application" userType="admin" [(item)]="application">
                </sa-application-selector>

                <caste-autocomplete-list-input [(value)]="roles"></caste-autocomplete-list-input>

                <mat-form-field>
                    <mat-label>{{'USERNAME' | translate}}</mat-label>
                    <input id="username" matInput placeholder="Enter username" formControlName="username">
                    <mat-error *ngIf="username.invalid && (username.dirty) && username.errors.required">
                        {{'USERNAME_REQUIRED' | translate}}
                    </mat-error>
                </mat-form-field>

                <kvp-list title="{{'USER_DATA' | translate}}" [items]="userData" (onReset)="reset()"
                    (onChange)="changedItems($event)"></kvp-list>

                <br>
                <br>

                <div class="flex-row end">
                    <qbit-loadable-button [id]="'createUserBtn'" [disabled]="userForm.invalid || isLoading"
                        [loading]="isLoading">
                        {{(isLoading ? 'EDITING': 'EDIT') |translate}}
                    </qbit-loadable-button>
                </div>
            </div>
        </div>
    </form>
</div>