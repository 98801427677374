<mat-card style="padding: 0; height: 100%;">
    <mat-card-header class="padd-15" style="margin: 0">
        <mat-card-title style="margin: 0">
            <div class="flex-row center">
                <span class="title text-mid">{{'USER_DETAILS'|translate}}</span>
            </div>
        </mat-card-title>
        <button mat-icon-button class="abs-center-right">
            <mat-icon>edit</mat-icon>
        </button>
    </mat-card-header>

    <mat-card-content class="padd-15">
        <div *ngIf="!isLoading && item">
            <div class="text-mid flex-row center space-between marg-bottom-15">
                <span>{{'NAME'|translate}}</span> <b>{{item.username}}</b>
            </div>
            <div class="text-mid flex-row center space-between marg-bottom-15">
                <span>{{'ID'|translate}}</span> <code>{{item.id}}</code>
            </div>
            <div class="text-mid flex-row center space-between marg-bottom-15">
                <span>{{'ROLES'|translate}}</span>
                <div>
                    <mat-chip-list>
                        <mat-chip class="mat-chip mat-primary mat-standard-chip {{role.className}}"
                            *ngFor="let role of item.roles">
                            {{role.name|translate}}
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>
            <div *ngIf="item.application | asyncp as application"
                class="text-mid flex-row center space-between marg-bottom-15">
                <span>{{'APPLICATION'|translate}}</span>
                <qbit-chip-link link="/applications/{{application.id}}" icon="apps" [label]="application.name">
                </qbit-chip-link>
            </div>
        </div>
    </mat-card-content>
</mat-card>