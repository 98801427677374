<qbit-dialog-header title="{{'EDIT_APPLICATION'|translate}}" (click)="close()"></qbit-dialog-header>

<div class="bootstrap-wrapper">
    <form class="padd-20" [formGroup]="applicationDetailsForm" (ngSubmit)="editApplication()">
        <div class="row">
            <div class="col-lg-12">
                <mat-form-field>
                    <mat-label>{{'NAME' | translate}}</mat-label>
                    <input id="name" matInput placeholder="Enter your name" formControlName="name">
                    <mat-error *ngIf="name.invalid && (name.dirty) && name.errors.required">
                        {{'NAME_REQUIRED' | translate}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{'REALM' | translate}}</mat-label>
                    <input id="realm" matInput placeholder="Enter the realm for the application"
                        formControlName="realm">
                    <mat-error *ngIf="realm.invalid && (realm.dirty) && realm.errors.required">
                        {{'REALM_REQUIRED' | translate}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{'GRANTS'}}</mat-label>
                    <mat-chip-list #chipList aria-label="valid grants for this application" formControlName="grants">
                        <mat-chip *ngFor="let grant of grants" [selectable]="false" [value]="grant"
                            [removable]="true" (removed)="removeGrant(grant)">
                            {{grant}}

                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                        <input placeholder="{{'NEW_GRANT'|translate}}..." [matChipInputFor]="chipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            (matChipInputTokenEnd)="addGrantFromInput($event)">
                    </mat-chip-list>
                </mat-form-field>

                <caste-autocomplete-list-input label="DEFAULT_GRANTS" newItemLabel="SELECT_DEFAULT_GRANTS"
                    [allValues]="grants" [(value)]="defaultGrants">
                </caste-autocomplete-list-input>


                <div class="flex-row end marg-top-20">
                    <qbit-loadable-button [id]="'editApplication'"
                        [disabled]="applicationDetailsForm.invalid || isLoading" [loading]="isLoading">
                        {{(isLoading ? 'EDITING': 'EDIT') |translate}}
                    </qbit-loadable-button>
                </div>
            </div>
        </div>
    </form>
</div>