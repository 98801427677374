<qbit-dialog-header title="{{'EDIT_ACCOUNT'|translate}}" (click)="close()"></qbit-dialog-header>

<div class="bootstrap-wrapper">
    <form class="padd-20" [formGroup]="accountDetailsForm" (ngSubmit)="addAccount()">
        <div class="row">
            <div class="col-lg-12">
                <p class="text-small" style="margin: 0 0 15px 0">
                    {{'EDIT_ACCOUNT_DESC' | translate}}
                </p>

                <sa-application-selector *ngIf="application | asyncp as app" userType="admin" [item]="app"
                    (itemChange)="application = $event;"></sa-application-selector>

                <sa-application-selector *ngIf="!application" userType="admin" [(item)]="application">
                </sa-application-selector>
                
                <mat-form-field>
                    <mat-label>{{'NAME' | translate}}</mat-label>
                    <input id="name" matInput placeholder="Enter your name" formControlName="name">
                    <mat-error *ngIf="name.invalid && (name.dirty) && name.errors.required">
                        {{'NAME_REQUIRED' | translate}}
                    </mat-error>
                </mat-form-field>

                <div style="margin: 0 0 15px 0">
                    <mat-checkbox formControlName="enabled">{{ 'ACCOUNT_ENABLED' | translate }}</mat-checkbox>
                </div>

                <div class="flex-row end">
                    <qbit-loadable-button [id]="'editAccount'"
                        [disabled]="accountDetailsForm.invalid || !application || isLoading || !hasEdit"
                        [loading]="isLoading">
                        {{(isLoading ? 'EDITING': 'EDIT') |translate}}
                    </qbit-loadable-button>
                </div>
            </div>
        </div>
    </form>
</div>