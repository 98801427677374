<qbit-dialog-header title="{{'EDIT_PERMISSION'|translate}}" (click)="close()"></qbit-dialog-header>

<div class="bootstrap-wrapper">
    <form class="padd-20" [formGroup]="permissionDetailsForm" (ngSubmit)="addPermission()">
        <div class="row">
            <div class="col-lg-12">
                <p class="text-mid">{{'EDIT_PERMISSION_DESC'|translate}}</p>

                <sa-user-selector [userType]="userType" *ngIf="user | asyncp as usr" [item]="usr"
                    (itemChange)="account = $event"></sa-user-selector>
                <sa-user-selector [userType]="userType" *ngIf="!user" [(item)]="user"></sa-user-selector>

                <sa-admin-account-selector *ngIf="account | asyncp as acc" [item]="acc" (itemChange)="account = $event">
                </sa-admin-account-selector>

                <sa-admin-account-selector *ngIf="!account" [(item)]="account">
                </sa-admin-account-selector>

                <caste-autocomplete-list-input label="SELECT_GRANTS" newItemLabel="NEW_GRANT"
                    [allValues]="availableGrants" [(value)]="grants"></caste-autocomplete-list-input>

                <div class="flex-row end">
                    <qbit-loadable-button [id]="'createPermission'"
                        [disabled]="permissionDetailsForm.invalid || !user || !account || grants.length <= 0 || isLoading"
                        [loading]="isLoading">
                        {{(isLoading ? 'ADDING': 'ADD') |translate}}
                    </qbit-loadable-button>
                </div>
            </div>
        </div>
    </form>
</div>